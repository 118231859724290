import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './Collapsable.module.scss';

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  title: '',
};

const Collapsable = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        type="button"
        className={[s.button, isOpen ? s.active : ''].join(' ')}
        onClick={handleCollapse}
      >
        {title}
      </button>
      {isOpen && <section className={s.content}>{children}</section>}
    </>
  );
};
Collapsable.propTypes = propTypes;
Collapsable.defaultProps = defaultProps;
export default Collapsable;
