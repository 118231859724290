import React from 'react';
import PropTypes from 'prop-types';
import Collapsable from '../../../components/Collapsable';
import s from './WorkScope.module.scss';

const propTypes = {
  scope: PropTypes.array,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  scope: [],
};

const WorkScope = ({ scope, title }) => {
  return (
    <div className={s.root}>
      <h2>{title}</h2>
      <section className={s.section}>
        {scope &&
          scope.map((item) => (
            <div key={item.id} className={s.group}>
              <h3>{item.title}</h3>
              <ul className={s.groupSection}>
                {item.workItem.map((wi) => (
                  <li key={Math.random()}>{wi}</li>
                ))}
              </ul>
            </div>
          ))}
      </section>
      <section className={s.mobile}>
        {scope &&
          scope.length &&
          scope.map((item, i) => {
            if (i !== scope.length - 1) {
              return (
                <React.Fragment key={Math.random()}>
                  <Collapsable title={item.title} key={Math.random()}>
                    <ul className={s.groupSection}>
                      {item.workItem.map((wi) => (
                        <li key={Math.random()}>{wi}</li>
                      ))}
                    </ul>
                  </Collapsable>
                  <hr className={s.divider} />
                </React.Fragment>
              );
            }
            return (
              <Collapsable title={item.title} key={Math.random()}>
                <ul className={s.groupSection}>
                  {item.workItem.map((wi) => (
                    <li key={Math.random()}>{wi}</li>
                  ))}
                </ul>
              </Collapsable>
            );
          })}
      </section>
    </div>
  );
};

WorkScope.defaultProps = defaultProps;
WorkScope.propTypes = propTypes;
export default WorkScope;
