import React from 'react';
import PropTypes from 'prop-types';
import withSize from 'react-sizeme';
import ViewType1 from './viewTypes/ViewType1';
import ViewType2 from './viewTypes/ViewType2';
import ViewType3 from './viewTypes/ViewType3';
import ViewType4 from './viewTypes/ViewType4';
import ViewType5 from './viewTypes/ViewType5';

const propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      hideTitle: PropTypes.bool,
      description: PropTypes.shape({ description: PropTypes.string.isRequired }),
      imageSliders: PropTypes.arrayOf(PropTypes.shape({ fluid: PropTypes.shape({}) })),
      backgroundColor: PropTypes.string,
      viewType: PropTypes.string,
    }),
  ).isRequired,
  size: PropTypes.shape({ width: PropTypes.number }),
};

const defaultProps = {
  size: {
    width: 1024,
  },
};

const Feature = ({ features, size }) => {
  return (
    <>
      {features &&
        features.map((feature) => {
          switch (feature.viewType) {
            case 'view1':
              return <ViewType1 item={feature} key={`${feature.viewType}+${Math.random()}`} />;
            case 'view2':
              return (
                <ViewType2
                  item={feature}
                  size={size}
                  key={`${feature.viewType}+${Math.random()}`}
                />
              );
            case 'view3':
              return <ViewType3 item={feature} key={`${feature.viewType}+${Math.random()}`} />;
            case 'view4':
              return (
                <ViewType4
                  item={feature}
                  size={size}
                  key={`${feature.viewType}+${Math.random()}`}
                />
              );
            case 'view5':
              return <ViewType5 item={feature} key={`${feature.viewType}+${Math.random()}`} />;
            default:
              return null;
          }
        })}
    </>
  );
};

Feature.propTypes = propTypes;
Feature.defaultProps = defaultProps;
export default withSize()(Feature);
