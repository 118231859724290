import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
// import descriptionExcerpt from '../../../utils/descriptionExcerpt';
import Button from '../../../components/Button';
import s from './CasestudyHeader.module.scss';

const propTypes = {
  bannerTitle: PropTypes.string.isRequired,
  headerBgColor: PropTypes.string,
  bannerDescription: PropTypes.shape({ bannerDescription: PropTypes.string }),
  bannerButtonTitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  referenceLink: PropTypes.string,
  featuredImage: PropTypes.shape({ fluid: PropTypes.shape({}) }),
};

const defaultProps = {
  headerBgColor: '',
  bannerDescription: {},
  bannerButtonTitle: '',
  referenceLink: '#',
  tags: [],
  featuredImage: PropTypes.shape({ fluid: PropTypes.shape({}) }),
};

const CasestudyHeader = ({
  bannerTitle,
  headerBgColor,
  bannerDescription,
  bannerButtonTitle,
  tags,
  featuredImage,
  referenceLink,
}) => {
  // const [isCollapsed, setIsCollapsed] = useState(true);

  const caseStyle = {
    background: headerBgColor,
  };

  return (
    <div className={s.root} style={caseStyle} data-aos="fade-up" data-aos-delay="300">
      <div className={s.headerContainer}>
        <div className={s.textContainer}>
          <section className={s.textSection}>
            <h2 data-aos="fade-up" data-aos-delay="400">
              {bannerTitle}
            </h2>
            {bannerDescription?.bannerDescription && (
              <p data-aos="fade-up" data-aos-delay="500">
                {bannerDescription?.bannerDescription}
              </p>
            )}
          </section>
          <div className={s.buttonTagsSection} data-aos="fade-up" data-aos-delay="700">
            {referenceLink && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={referenceLink}
                className={s.button}
              >
                <Button>{bannerButtonTitle}</Button>
              </a>
            )}
            <ul className={s.tags}>
              {tags &&
                tags.length > 0 &&
                tags.map((tag) => (
                  <li key={Math.random()} className={s.tagItems}>
                    {tag}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className={s.imageContainer} data-aos="fade" data-aos-delay="800">
          <div className={s.imageSection}>
            <Image width={100} fluid={featuredImage.fluid} />
          </div>
        </div>
      </div>
    </div>
  );
};

CasestudyHeader.defaultProps = defaultProps;
CasestudyHeader.propTypes = propTypes;
export default CasestudyHeader;
